import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store/index.js'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || store.getters.getLocale || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: require('../locales/en.json'),
    de: require('../locales/de.json')
  }
})
