// https://stackoverflow.com/a/62677397/1128689
import store from '../store/index.js'

export const JsonWebToken = {
  /**
   * This function parses the given JSON Web Token to retrieve its payload and data.
   * It returns a JSON Object with the two keys:
   * - 'sub' containing the username
   * - 'exp' containing the expiry date as JavaScript Date in seconds
   *
   * @param {string}  token  The JSON Web Token
   */
  parseJwt(token) {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      Buffer.from(base64, 'base64')
        .toString('ascii')
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  },

  /**
   * This function parses the given JSON Web Token and checks its expiry date,
   * the subject (username) and the role claim.
   *
   * @param {string}  token  The JSON Web Token
   * @returns {boolean} whether or not the JWT is valid
   */
  isValid(token) {
    const decodedJwt = this.parseJwt(token)
    const checkIsNotExpired = decodedJwt.exp > Date.now() / 1000
    const checkUserNameMatches = decodedJwt.sub === store.getters.getCurrentUser.username
    const checkUserRoleMatches = decodedJwt.role === store.getters.getCurrentUser.role
    return checkIsNotExpired && checkUserNameMatches && checkUserRoleMatches
  },

  /**
   * This function parses the given JSON Web Token and returns the username string
   *
   * @param {string}  token  The JSON Web Token
   * @returns {string} the username stored in the JWT
   */
  getUsername(token) {
    return this.parseJwt(token).sub
  }
}
