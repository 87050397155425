import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueDayjs from 'vue-dayjs-plugin'

import './styles/stylesheet.scss'

import router from './router'
import store from './store'
import { i18n } from './plugins/i18n.js'
import Gravatar from 'vue-gravatar'
import App from './App.vue'
import Ellipsis from 'vue-directive-ellipsis'
import VueLazyload from 'vue-lazyload'
import 'vue-directive-ellipsis/dist/ellipsis.umd.css'
import VueCookies from 'vue-cookies'

// leaflet map components
import { LIconDefault, LMap, LMarker, LPopup, LTileLayer } from 'vue2-leaflet'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueDayjs)
Vue.use(VueLazyload)
Vue.use(VueCookies)
Vue.component('v-gravatar', Gravatar)
Vue.directive('ellipsis', Ellipsis)

// leaflet map components
Vue.component('v-map', LMap)
Vue.component('v-tile-layer', LTileLayer)
Vue.component('v-icon-default', LIconDefault)
Vue.component('v-popup', LPopup)
Vue.component('v-marker', LMarker)
Vue.component('v-marker-cluster', Vue2LeafletMarkercluster)

Vue.config.productionTip = false

store.dispatch('fetchConfig').then(() => {
  return new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
