<template>
  <div class="map-container">
    <v-map
      class="sensor-map"
      :zoom="map.zoom"
      :center="map.defaultCenter"
      ref="map"
      :class="[map.style, { tint: map.tint }]"
      :options="map.leafletOptions"
      gestureHandling
    >
      <v-icon-default />
      <v-tile-layer :url="map.url" :attribution="map.attribution" />
      <v-marker-cluster :options="clusterOptions">
        <v-marker
          v-for="(sensor, index) in allSensors"
          :key="sensor.sensorId"
          :lat-lng="sensorCoordinates[index]"
          :ref="`marker${sensor.sensorId}`"
        >
          <v-popup :ref="`popup${sensor.sensorId}`" @add="onPopupShow(sensor)" @remove="onPopupHide">
            <b-container class="popup-container" :key="updateKey">
              <b-row class="mb-1 mt-4">
                <p class="popup-header-2">{{ `Letzte Messung von Sensor #${activeSensorPopup.sensorId}` }}</p>
              </b-row>
              <b-row class="mb-3">
                <h1 class="popup-header-1">
                  {{ activeSensorPopup.locationName }},
                  <span class="city">{{ `${activeSensorPopup.postcode} ${activeSensorPopup.city}` }}</span>
                </h1>
              </b-row>
              <b-row v-if="popupLoading" class="d-flex justify-content-center">
                <div class="d-flex justify-content-center mb-5 mt-4">
                  <b-spinner class="popup-spinner" variant="dark" label="Loading..." />
                </div>
              </b-row>
              <b-row v-else-if="activeSensorPopup.datasets.length === 0" class="d-flex justify-content-start mb-4">
                <div class="d-flex justify-content-start mb-3">
                  <p class="popup-header-2">{{ `Für Sensor #${sensor.sensorId} existieren keine Datensätze` }}</p>
                </div>
              </b-row>
              <b-row
                v-else-if="screen.sm || screen.xs"
                class="d-flex justify-content-start align-items-center user-select-none mb-4"
              >
                <b-col cols="1" class="popup-col-sm" @click="prevSlidePopup">
                  <b-icon font-scale="2" class="prev-next-button" icon="caret-left-fill" variant="primary" />
                </b-col>
                <b-col cols="10" class="popup-col-sm">
                  <div class="w-100 justify-content-center display-flex">
                    <img
                      v-if="activeSlidePopup.image_data"
                      class="popup-img"
                      v-lazy="activeSlidePopup.image_data.src || activeSlidePopup.image_data.thumb"
                      @click="openPopupGallery()"
                    />
                    <img v-else class="popup-img" src="http://placehold.it/500/bbbbbb/fff&amp;text=Kein Bild" />
                  </div>
                </b-col>
                <b-col cols="1" class="popup-col-sm" @click="nextSlidePopup">
                  <b-icon font-scale="2" class="prev-next-button" icon="caret-right-fill" variant="primary" />
                </b-col>
                <b-col cols="12" class="justify-content-center">
                  <div class="text-center w-100">
                    <p class="popup-date">
                      {{ formatDateTime(activeSlidePopup.measurement_datetime) }}
                    </p>
                    <p class="classification">
                      {{
                        activeSlidePopup.classification
                          ? formatClassifications(activeSlidePopup.classification)
                          : '- nicht klassifiziert -'
                      }}
                    </p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-else class="d-flex justify-content-start mb-4">
                <b-col cols="4" v-for="dataset in activeSensorPopup.datasets" v-bind:key="dataset.datasetId">
                  <div class="w-100 justify-content-center display-flex">
                    <img
                      v-if="dataset.image_data"
                      class="popup-img"
                      v-lazy="dataset.image_data.src || dataset.image_data.thumb"
                      @click="openPopupGallery()"
                    />
                    <img v-else class="popup-img" src="http://placehold.it/500/bbbbbb/fff&amp;text=Kein Bild" />
                  </div>
                  <div class="text-center w-100">
                    <p class="popup-date">
                      {{ formatDateTime(dataset.measurement_datetime) }}
                    </p>
                    <p class="classification">
                      {{
                        dataset.classification
                          ? formatClassifications(dataset.classification)
                          : '- nicht klassifiziert -'
                      }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </v-popup>
        </v-marker>
      </v-marker-cluster>
    </v-map>

    <!-- Lightbox for popup dataset images -->
    <LightBox :show-light-box="false" ref="popupLightbox" v-if="popupImages.length > 0" :media="popupImages" />

    <div v-if="latestDatasets.length !== 0" class="mt-4 flex-box justify-content-center">
      <b-container class="carousel-container" :style="screen.xs || screen.sm ? 'width: 300px' : ''">
        <b-row class="justify-content-center mb-1">
          <b-pagination
            v-model="slide"
            pills
            :total-rows="numberOfImagesBottom"
            :per-page="screen.sm || screen.xs ? 1 : 4"
            :current-page="currentPage"
            size="sm"
            hide-goto-end-buttons
            next-class="hide"
            prev-class="hide"
            class="color-transparent"
          />
        </b-row>
        <b-row class="align-items-center user-select-none justify-content-center">
          <b-col cols="1" class="full-height display-flex justify-content-center cursor-pointer" @click="prevSlide">
            <b-icon font-scale="2" class="prev-next-button" icon="caret-left-fill" variant="primary" />
          </b-col>
          <b-col>
            <b-row class="justify-content-center">
              <b-col
                cols="10"
                md="3"
                lg="3"
                v-for="(dataset, index) in activeSlide"
                :key="dataset.datasetId ? `cid-${dataset.datasetId}` : `idx-${index}`"
              >
                <b-row class="justify-content-center">
                  <img
                    v-if="dataset.datasetId && dataset.image_id"
                    class="carousel-img cursor-pointer"
                    v-lazy="getImageUrl(dataset.datasetId, dataset.image_id)"
                    alt="img"
                    @click="openGallery(index)"
                  />
                  <img v-else class="carousel-no-img" src="http://placehold.it/500/007bff/fff&amp;text=Kein Bild" />
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1" class="full-height display-flex justify-content-center cursor-pointer" @click="nextSlide">
            <b-icon font-scale="2" class="prev-next-button" icon="caret-right-fill" variant="primary" />
          </b-col>
        </b-row>
        <b-row class="info-row">
          <b-col cols="1" />
          <b-col>
            <b-row class="justify-content-center">
              <b-col
                cols="10"
                md="3"
                lg="3"
                v-for="(dataset, index) in activeSlide"
                :key="dataset.datasetId ? `cid-${dataset.datasetId}` : `idx-${index}`"
              >
                <div class="text-center">
                  <p class="date">{{ formatDate(dataset.measurement_datetime) }}</p>
                  <p class="location">
                    {{ formatCity(dataset.public_sensor_location) }}, {{ dataset.public_sensor_location.name }}
                  </p>
                  <p class="classification">{{ getMainClassification(dataset.classification) }}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1" />
        </b-row>
      </b-container>

      <!-- Lightbox for latest dataset images -->
      <LightBox
        :show-light-box="false"
        ref="lightbox"
        v-if="latestDatasetsImages.length > 0"
        :media="latestDatasetsImages"
      />
    </div>
  </div>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { Icon, latLng } from 'leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'
import breakpoints from '@/util/BreakpointsUtil'
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import axios from 'axios'
import LightBox from 'vue-image-lightbox' // Use only when you are using Webpack:
// Use only when you are using Webpack:
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

/**
 *  See https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing for reference
 *
 *  If the marker icons are missing the issue lies in a problem with webpack, a quick fix is to run this snippet:
 */
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'SensorMap',
  mixins: [ApiMixin],
  // eslint-disable-next-line vue/no-unused-components
  components: { GestureHandling, LightBox },
  data() {
    return {
      apiBaseUrl: this.$store.getters.getConfig.API_BASE_URL,
      map: {
        style: 'osm',
        tint: true,
        url: 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 6,
        defaultCenter: [51.1642292, 10.4541194],
        leafletOptions: {
          gestureHandling: true,
          gestureHandlingOptions: {
            duration: 1000,
            text: {
              touch: 'Verschiebe oder Zoome die Karte mit zwei Fingern',
              scroll: 'Zoome die Karte mit STRG + Scroll',
              scrollMac: 'Zoome die Karte mit \u2318 + Scroll'
            }
          }
        }
      },
      clusterOptions: {
        spiderfyDistanceMultiplier: 3
      },
      slide: 1,
      slidePopup: 1,
      currentPage: 1,
      currentPagePopup: 1,
      allSensors: [],
      sensorCoordinates: [],
      popupLoading: false,
      activeSensorPopup: {
        sensorId: null,
        locationName: '',
        city: '',
        postcode: null,
        datasets: []
      },
      popupImages: [],
      updateKey: false,
      latestDatasets: [],
      latestDatasetsImages: []
    }
  },
  created() {
    // Reset a potential outdated Authorization token from the internal webapp
    delete axios.defaults.headers.common['Authorization']

    // Change mapStyle based on request param
    if (!!this.$route.query && !!this.$route.query.style) {
      const queryMapStyle = this.$route.query.style
      if (queryMapStyle === 'stadia') {
        this.map.style = 'stadia'
        this.map.tint = false
        this.map.url = 'https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png'
        this.map.attribution =
          '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a> contributors'
      }
    }
    if (!!this.$route.query && !!this.$route.query.tint) {
      const queryMapTint = this.$route.query.tint
      if (queryMapTint === 'true') {
        this.map.tint = true
      } else {
        this.map.tint = false
      }
    }
  },
  mounted() {
    // First load public sensor information to place map markers
    this.loadPublicSensorsInformation()

    // Then load latest datasets from all sensors
    this.loadLatestDatasets()

    setTimeout(() => {
      this.$nextTick(() => {
        this.clusterOptions = { disableClusteringAtZoom: 11 }
      })
    }, 1000)
  },
  computed: {
    screen() {
      return breakpoints().screen
    },
    numberOfImagesPerSlide() {
      return this.screen.xs || this.screen.sm ? 1 : 4
    },
    numberOfImagesPerSlidePopup() {
      return 1
    },
    numberOfImagesBottom() {
      if (!this.latestDatasets) {
        return 0
      }
      return this.latestDatasets.length
    },
    numberOfImagesPopup() {
      return this.activeSensorPopup.datasets.length
    },
    numberOfSlides() {
      return Math.ceil(this.numberOfImagesBottom / this.numberOfImagesPerSlide)
    },
    numberOfSlidesPopup() {
      return this.numberOfImagesPopup
    },
    activeSlide() {
      let images = []
      const startIndex = (this.slide - 1) * this.numberOfImagesPerSlide
      const endIndex = startIndex + this.numberOfImagesPerSlide
      this.latestDatasets.forEach((img, index) => {
        if (startIndex <= index && index < endIndex) {
          images.push(img)
        }
      })
      return images
    },
    activeSlidePopup() {
      return this.activeSensorPopup.datasets[this.slidePopup - 1]
    }
  },
  methods: {
    latLng,
    onPopupShow(sensor) {
      this.activeSensorPopup.sensorId = sensor.sensorId
      this.activeSensorPopup.locationName = sensor.sensorLocation.name
      this.activeSensorPopup.city = sensor.sensorLocation.city
      this.activeSensorPopup.postcode = sensor.sensorLocation.postcode
      this.loadPopupDatasets(sensor.sensorId)
    },
    onPopupHide() {
      this.clearActiveSensorPopup()
    },
    clearActiveSensorPopup() {
      this.activeSensorPopup.sensorId = null
      this.activeSensorPopup.locationName = ''
      this.activeSensorPopup.city = ''
      this.activeSensorPopup.postcode = null
      this.activeSensorPopup.datasets = []
      this.popupImages.splice(0)
    },
    getImageUrl(datasetId, imageId) {
      return `${this.apiBaseUrl}/public/images/${datasetId}/${imageId}`
    },
    formatDateTime(value) {
      return LocalDateTimeFormatter.formatLocaleDateTime(value)
    },
    formatDate(value) {
      return LocalDateTimeFormatter.formatLocaleDate(value)
    },
    formatCity(sensorLocation) {
      return `${sensorLocation.postcode} ${sensorLocation.city}`
    },
    formatClassifications(classification) {
      return classification.replaceAll(';', ' /')
    },
    getMainClassification(classification) {
      const classificationArray = classification.split(';')
      return classificationArray[classificationArray.length - 1]
    },
    setImageUrls() {
      this.latestDatasets.forEach(dataset => {
        this.latestDatasetsImages.push({
          thumb: `${this.apiBaseUrl}/public/images/${dataset.datasetId}/${dataset.image_id}`,
          src: `${this.apiBaseUrl}/public/images/${dataset.datasetId}/${dataset.image_id}`
        })
      })
    },
    setPopupImageUrl() {
      this.activeSensorPopup.datasets.forEach(dataset => {
        this.popupImages.push({
          thumb: `${this.apiBaseUrl}/public/images/${dataset.datasetId}/${dataset.image_id}`,
          src: `${this.apiBaseUrl}/public/images/${dataset.datasetId}/${dataset.image_id}`
        })
      })
    },
    openGallery(index) {
      const actualIndex = index + (this.slide - 1) * this.numberOfImagesPerSlide
      this.$refs.lightbox.showImage(actualIndex)
    },
    openPopupGallery() {
      const actualIndex = this.slidePopup - 1
      this.$refs.popupLightbox.showImage(actualIndex)
    },
    // -- Carousel handlers --------------------------------------------------------------------------------------------
    prevSlide() {
      if (this.slide === 1) {
        // this.slide = this.numberOfSlides
      } else {
        this.slide -= 1
      }
    },
    nextSlide() {
      if (this.slide === this.numberOfSlides) {
        // this.slide = 1
      } else {
        this.slide += 1
      }
    },
    // -- Carousel Popup handlers --------------------------------------------------------------------------------------
    prevSlidePopup() {
      if (this.slidePopup === 1) {
        this.slidePopup = this.numberOfSlidesPopup
      } else {
        this.slidePopup -= 1
      }
    },
    nextSlidePopup() {
      if (this.slidePopup === this.numberOfSlidesPopup) {
        this.slidePopup = 1
      } else {
        this.slidePopup += 1
      }
    },
    // -- API calls ----------------------------------------------------------------------------------------------------
    loadLatestDatasets() {
      const url = '/public/datasets/latest'
      let self = this
      self.getRequest(
        url,
        new RequestConfig()
          .onSuccess(res => {
            self.latestDatasets = res.data
            self.setImageUrls()
          })
          .onError(error => {
            // TODO: Error Handling
            console.log('error: ', error)
          })
      )
    },
    loadPublicSensorsInformation() {
      const url = '/public/sensors'
      let self = this
      self.getRequest(
        url,
        new RequestConfig()
          .onSuccess(res => {
            res.data.forEach(sensor => {
              self.sensorCoordinates.push(latLng(sensor.sensorLocation.latitude, sensor.sensorLocation.longitude))
            })
            self.allSensors = res.data
          })
          .onError(error => {
            // TODO: Error Handling
            console.log('error: ', error)
          })
      )
    },
    loadPopupDatasets(sensorId) {
      const startTime = Date.now()
      this.popupLoading = true
      const url = `/public/sensors/${sensorId}`
      let self = this
      self.getRequest(
        url,
        new RequestConfig()
          .onSuccess(res => {
            const timeout = Date.now() - startTime >= 750 ? 0 : 750
            setTimeout(() => {
              self.activeSensorPopup.datasets = res.data
              self.activeSensorPopup.datasets.forEach(dataset => {
                if (dataset.image_id) {
                  dataset.image_data = {
                    src: `${this.apiBaseUrl}/public/images/${dataset.datasetId}/${dataset.image_id}`,
                    thumb: `${this.apiBaseUrl}/public/images/${dataset.datasetId}/${dataset.image_id}`
                  }
                }
              })
              self.setPopupImageUrl()
              self.updateKey = !self.updateKey
              this.popupLoading = false
            }, timeout)
          })
          .onError(error => {
            // TODO: Error handling for error message in popup?
            console.log('error: ', error)
            this.popupLoading = false
          })
      )
    }
  }
}
</script>

<style src="leaflet-gesture-handling/dist/leaflet-gesture-handling.css" />

<style lang="scss">
.sensor-map {
  min-height: 700px !important;

  a:hover {
    text-decoration: none !important;
  }

  .leaflet-popup {
    width: clamp(350px, 60vw, 600px);
    .popup-col-sm {
      padding: 0 !important;
    }

    .leaflet-popup-content {
      width: auto !important;
    }
  }
}

.map-container {
  .page-link {
    color: transparent !important;
    padding: 0 !important;
    width: 13px !important;
    height: 13px !important;
  }
}

.hide {
  display: none;
}

// OpenStreetmap styling from existing map at map.kinsecta.org
.tint .leaflet-tile {
  filter: grayscale(85%) saturate(150%) hue-rotate(180deg) contrast(90%) brightness(110%) !important;
  -webkit-filter: grayscale(85%) saturate(150%) hue-rotate(180deg) contrast(90%) brightness(110%) !important;
}

.popup-container {
  .popup-header-2 {
    margin: 0;
    font-size: 0.9rem;
    padding-left: 15px;
  }
  .popup-header-1 {
    font-size: 1.1rem;
    padding-left: 15px;

    .city {
      font-weight: normal;
    }
  }
  .popup-img {
    border: 1px solid dimgray;
    width: 100%;
    max-width: 180px;
    max-height: 180px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    cursor: zoom-in;
  }
  p.popup-date {
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding-bottom: 0.3rem;
  }
  .classification {
    margin-top: 0.5rem;
  }
  .popup-spinner {
    opacity: 0.4;
    width: 75px;
    height: 75px;
  }
}

.carousel-container {
  width: clamp(700px, 40vw, 900px);

  .carousel-img {
    border: 1px solid dimgray;
    width: 90%;
    max-width: 200px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    cursor: zoom-in;
  }

  .carousel-no-img {
    opacity: 40%;
  }

  .prev-next-button {
    opacity: 0.7;
    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.display-flex {
  display: flex;
}

p.date {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.1rem;
}

p.location {
  margin-bottom: 0.3rem;
  font-size: 0.7rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.3rem;
}
</style>
