<template>
  <div class="h-100">
    <!-- Public Map -->
    <div v-if="$router.currentRoute.name === 'map'">
      <SensorMap />
    </div>

    <!-- Internal BackendApplication -->
    <div v-else id="app" class="d-flex flex-column h-100">
      <AppHeader />
      <main class="flex-shrink-0 container-fluid px-5 px-sm-2">
        <router-view />
      </main>
      <AppFooter />
    </div>
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter'
import AppHeader from '@/components/AppHeader'
import SensorMap from '@/pages/SensorMap.vue'

export default {
  name: 'app',
  components: { AppHeader, AppFooter, SensorMap }
}
</script>

<style lang="scss">
#app > main {
  padding-top: 140px;
  padding-bottom: 5rem;
}
</style>
