<template>
  <footer id="footer" class="footer mt-auto py-3">
    <b-container fluid="xxl">
      <b-row class="mx-auto">
        <b-col>
          <p>© 2022 KInsecta</p>
        </b-col>
        <b-col>
          <a class="footer-link" href="https://kinsecta.org/impressum/" target="_blank">{{ $t('general.imprint') }}</a>
        </b-col>
        <b-col>
          <a class="footer-link" href="https://kinsecta.org/privacy-policy/" target="_blank">{{
            $t('general.privacyPolicies')
          }}</a>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style lang="scss" scoped>
#footer {
  background-color: var(--light);
}

#footer > .container {
  width: 100%;
}

a {
  text-decoration: underline;
  display: block;
  color: var(--gray-900);

  &:hover {
    color: var(--secondary);
  }
}
</style>
