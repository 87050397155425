<template>
  <header id="header">
    <b-navbar variant="light" type="light" fixed="top" role="navigation" class="d-flex flex-wrap flex-md-wrap">
      <b-navbar-brand href="#" :to="{ name: isUploadAllowed ? 'upload' : 'datasets' }" class="order-1">
        <img
          src="../static/logo.webp"
          height="50"
          width="55"
          class="d-inline-block d-md-none align-top logo"
          alt="KInsecta"
        />
        <img src="../static/logo.webp" height="50" class="d-none d-md-inline-block align-top logo" alt="KInsecta" />
      </b-navbar-brand>

      <b-navbar-nav id="nav" class="order-3 order-sm-2 mx-auto mx-sm-0 px-5 px-sm-0">
        <b-nav-item v-if="isLoggedIn && isUploadAllowed" :to="{ name: 'upload' }" active-class="active">{{
          $t('upload.link')
        }}</b-nav-item>

        <b-nav-item
          v-if="isLoggedIn"
          :to="$route.path === '/datasets' ? $route.fullPath : { name: 'datasets' }"
          active-class="active"
          >{{ $t('dataset.link') }}</b-nav-item
        >
      </b-navbar-nav>

      <b-navbar-nav id="secondary-nav" class="ml-auto order-2 order-sm-3 pl-4">
        <b-nav-item-dropdown
          right
          v-if="isAdmin || isTeamMember"
          id="entity-management-item"
          class="mr-2 icon-dropdown"
        >
          <template #button-content>
            <b-icon icon="diagram-3" />
            <b-tooltip target="entity-management-item" :title="$t('settings.entity-management')" triggers="hover" />
          </template>
          <b-dropdown-group :header="$t('settings.entity-management')">
            <b-dropdown-item :to="{ name: 'manage_insects' }">{{ $t('insect.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_tags' }">{{ $t('tag.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_sensors' }">{{ $t('sensor.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_sensor_locations' }">{{
              $t('sensor_location.link')
            }}</b-dropdown-item>
          </b-dropdown-group>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right v-if="isAdmin" id="settings-item" class="mr-2 icon-dropdown">
          <template #button-content>
            <b-icon icon="gear" />
            <b-tooltip target="settings-item" :title="$t('settings.settings')" triggers="hover" />
          </template>
          <b-dropdown-group :header="$t('settings.settings')">
            <b-dropdown-item :to="{ name: 'manage_users' }">{{ $t('user.link') }}</b-dropdown-item>
          </b-dropdown-group>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right v-if="isLoggedIn">
          <template #button-content>
            <b-avatar size="36px" rounded="lg" variant="white">
              <v-gravatar :size="36" default-img="mm" :email="$store.getters.getCurrentUser.email" class="gravatar" />
            </b-avatar>
          </template>
          <b-dropdown-item :to="{ name: 'account_profile' }">{{ $t('profile.link') }}</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item :to="{ name: 'logout' }">{{ $t('login.logout') }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </header>
</template>

<script>
import { ApiMixin } from '@/mixins/ApiMixin'

export default {
  name: 'AppHeader',
  mixins: [ApiMixin],
  computed: {
    isUploadAllowed() {
      return this.isAdmin || this.isTeamMember || this.isDataCollector || this.isDataRecipient
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables';

@media (max-width: 767.98px) {
  .logo {
    object-fit: cover;
    object-position: 0 0;
  }
}

.nav-item {
  align-self: center;
}

.nav-item.icon-only .nav-link {
  display: inline;
  vertical-align: super;
}

#nav .nav-item .nav-link,
#secondary-nav .nav-item .nav-link {
  color: var(--dark);
}

#nav .nav-item .nav-link {
  border-bottom: 3px solid transparent;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
#nav .nav-item .nav-link {
  color: var(--dark);
}
#nav .nav-item .nav-link.active,
#nav .nav-item .nav-link:active,
#nav .nav-item .nav-link:focus,
#nav .nav-item:hover .nav-link {
  color: $spot-color-blue;
  background-color: initial;
  border-bottom: 3px solid $spot-color-blue;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#secondary-nav .icon-dropdown {
  margin-top: -6px;
}
</style>

<style lang="scss">
#secondary-nav .icon-dropdown {
  .dropdown-toggle::after {
    vertical-align: 0.07em;
  }
  .dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
