import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import { JsonWebToken } from '@/util/JsonWebToken'
import { UserRole } from '@/constants/UserRole'

Vue.use(VueRouter)

const routes = [
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "SensorMap" */ '../pages/SensorMap.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "mainLogin" */ '../pages/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "mainLogout" */ '../pages/Logout.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import(/* webpackChunkName: "mainUpload" */ '../pages/Upload.vue'),
    meta: {
      authorize: [UserRole.TeamMember, UserRole.Admin, UserRole.DataCollector, UserRole.DataRecipient]
    }
  },
  {
    path: '/datasets',
    name: 'datasets',
    component: () => import(/* webpackChunkName: "mainDatasets" */ '../pages/Datasets.vue'),
    meta: {
      authorize: [
        UserRole.TeamMember,
        UserRole.Admin,
        UserRole.DataCollector,
        UserRole.DataScientist,
        UserRole.DataRecipient
      ]
    }
  },
  {
    path: '/datasets/:datasetId/:mode',
    name: 'datasetEdit',
    component: () => import(/* webpackChunkName: "datasetEdit" */ '../pages/DatasetEdit.vue'),
    meta: {
      authorize: [
        UserRole.TeamMember,
        UserRole.Admin,
        UserRole.DataCollector,
        UserRole.DataScientist,
        UserRole.DataRecipient
      ]
    },
    beforeEnter(to, from, next) {
      if (
        to.params !== null &&
        to.params.mode === 'edit' &&
        !store.getters.isCurrentUserAdmin &&
        !store.getters.isCurrentUserTeamMember
      ) {
        next({ name: '403' })
      } else {
        next()
      }
    }
  },
  {
    path: '/account/profile',
    name: 'account_profile',
    component: () => import(/* webpackChunkName: "accountProfile" */ '../pages/account/Profile.vue'),
    meta: {
      authorize: []
    }
  },
  {
    path: '/account/begin_password_reset',
    name: 'account_passwordResetRequest',
    component: () =>
      import(/* webpackChunkName: "accountPasswordResetRequest" */ '../pages/account/PasswordResetRequest.vue')
  },
  {
    path: '/account/password_reset',
    name: 'account_passwordReset',
    component: () => import(/* webpackChunkName: "accountPasswordReset" */ '../pages/account/PasswordReset.vue')
  },
  {
    path: '/manage/users',
    name: 'manage_users',
    component: () => import(/* webpackChunkName: "manageUsers" */ '../pages/manage/Users.vue'),
    meta: {
      authorize: [UserRole.Admin]
    }
  },
  {
    path: '/manage/insects',
    name: 'manage_insects',
    component: () => import(/* webpackChunkName: "manageInsects" */ '../pages/manage/Insects.vue'),
    meta: {
      authorize: [UserRole.TeamMember, UserRole.Admin]
    }
  },
  {
    path: '/manage/tags',
    name: 'manage_tags',
    component: () => import(/* webpackChunkName: "manageTags" */ '../pages/manage/Tags.vue'),
    meta: {
      authorize: [UserRole.TeamMember, UserRole.Admin]
    }
  },
  {
    path: '/manage/sensors',
    name: 'manage_sensors',
    component: () => import(/* webpackChunkName: "manageSensors" */ '../pages/manage/Sensors.vue'),
    meta: {
      authorize: [UserRole.TeamMember, UserRole.Admin]
    }
  },
  {
    path: '/manage/sensor_locations',
    name: 'manage_sensor_locations',
    component: () => import(/* webpackChunkName: "manageSensorLocations" */ '../pages/manage/SensorLocations.vue'),
    meta: {
      authorize: [UserRole.TeamMember, UserRole.Admin]
    }
  },
  {
    path: '/',
    beforeEnter(to, from, next) {
      if (store.getters.isTokenSet && JsonWebToken.isValid(store.getters.getToken)) {
        if (store.getters.isCurrentUserDataScientist) {
          next({ name: 'datasets' })
        } else {
          next({ name: 'upload' })
        }
      } else {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/error/403_forbidden',
    name: '403',
    component: () => import(/* webpackChunkName: "error403" */ '../pages/errors/403Forbidden.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "error404" */ '../pages/errors/404NotFound.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta
  if (authorize) {
    if (store.getters.isTokenSet) {
      if (JsonWebToken.isValid(store.getters.getToken)) {
        const currentUserRole = store.getters.getCurrentUser.role
        // check if route is restricted by role
        if (authorize.length && !authorize.includes(currentUserRole)) {
          // role not authorised so redirect to 403 page
          return next({ name: '403' })
        } else {
          next()
        }
      } else {
        store.commit('setLogoutErrorMessageKey', 'errors.not-authorized.default')
        next({
          path: '/logout',
          query: {
            redirectUrl: to.path
          }
        })
      }
    } else {
      next({
        path: '/login',
        query: {
          redirectUrl: to.path
        }
      })
    }
  } else {
    next()
  }
})

export default router
