export const UserRole = Object.freeze({
  Admin: 'ROLE_ADMIN',
  TeamMember: 'ROLE_TEAM_MEMBER',
  DataCollector: 'ROLE_DATA_COLLECTOR',
  DataScientist: 'ROLE_DATA_SCIENTIST',
  DataRecipient: 'ROLE_DATA_RECIPIENT'
})

export const getUserRoleForValue = value => {
  return Object.keys(UserRole).find(r => UserRole[r] === value)
}
